import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSetActiveTab from "../../../../components/hooks/useSetActiveTab";
import TabHeader from "../../../../components/layouts/TabHeader";
import { TAB_NAMES } from "../../../../components/layouts/menuNavigation";
import DeleteModal from "../../../../components/modals/DeleteModal";
import { useNotification } from "../../../../components/notification/NotificationContext";
import { useDeleteGymShift, useGetGymShifts } from "../apis";

const ShiftsList = () => {
  useSetActiveTab(TAB_NAMES.GYM);
  const navigate = useNavigate();
  const { successNotification, errorNotification } = useNotification();
  const [idToOperatem, setIdToOperate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteClick = useCallback((staffId) => {
    setIdToOperate(staffId);
    setIsDeleteModalOpen(true);
  }, []);

  const columns = useColumns(handleDeleteClick);
  const {
    isLoading,
    data,
    mutate: refreshExpenses,
    isValidating,
  } = useGetGymShifts();

  const {
    isLoading: isDeleteLoading,
    execute: deleteGymStaff,
    error: deleteGymStaffError,
    isSuccess: deleteGymStaffSuccess,
  } = useDeleteGymShift();

  useEffect(() => {
    if (deleteGymStaffSuccess) {
      refreshExpenses();
      successNotification();
      setIsDeleteModalOpen(false);
    }
  }, [deleteGymStaffSuccess, refreshExpenses, successNotification]);

  useEffect(() => {
    if (deleteGymStaffError) {
      errorNotification();
    }
  }, [deleteGymStaffError, errorNotification]);

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onSubmit={() => deleteGymStaff({ expenseId: idToOperatem })}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
      <div className="flex flex-col gap-5">
        <TabHeader
          label="Shifts"
          buttonLabel="Add Shift"
          onButtonClick={() => navigate("/gym/shifts/add")}
          onReload={refreshExpenses}
        />
        <Table
          columns={columns}
          dataSource={data?.data?.rows ?? []}
          loading={isLoading || isValidating}
          scroll={{ x: true }}
        />
      </div>
    </>
  );
};

export default ShiftsList;

const useColumns = (handleDeleteClick) => {
  const navigate = useNavigate();

  return [
    {
      title: () => <div className="flex justify-center">Name</div>,
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div className="flex justify-center">{text ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Start Time</div>,
      dataIndex: "startTime",
      key: "startTime",
      render: (text) => (
        <div className="flex justify-center">
          {dayjs(text).format("HH:mm") ?? "-"}
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">End Time</div>,
      dataIndex: "endTime",
      key: "endTime",
      render: (text) => (
        <div className="flex justify-center">
          {dayjs(text).format("HH:mm") ?? "-"}
        </div>
      ),
    },

    {
      title: () => <div className="flex justify-center">Actions</div>,
      key: "id",
      // fixed: "right",
      render: (_, record) => (
        <div className="flex justify-center gap-3">
          <Button
            type="primary"
            onClick={() => navigate(`/gym/shifts/add/${record.id}`)}
            icon={<EditOutlined />}
          ></Button>
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteClick(record.id)}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
      ),
    },
  ];
};

export const DateCell = ({ date }) => {
  return (
    <div className="flex justify-center">
      {date ? dayjs(date).format("DD MMM YYYY") : "-"}
    </div>
  );
};
