import { LoadingOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, DatePicker, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { number, object, string } from "yup";
import useSetActiveTab from "../../../components/hooks/useSetActiveTab";
import Input, { ElementWithLabel } from "../../../components/inputs/Input";
import { TAB_NAMES } from "../../../components/layouts/menuNavigation";
import { useNotification } from "../../../components/notification/NotificationContext";
import {
  LEAD_SOURCE_OPTIONS,
  LEAD_STATUS_OPTIONS,
} from "../../../utils/constants";
import { HorizontalSeparator } from "../../members/create/AddMember";
import { genderOptions } from "../../workouts/exercise-filters/constants";
import {
  useAddGymEnquiry,
  useGetGymEnquiryInfo,
  useUpdateGymEnquiry,
} from "../apis";

const FORM_FIELDS = {
  NAME: "name",
  EMAIL: "email",
  GENDER: "gender",
  SOURCE: "source",
  LEAD_STATUS: "leadStatus",
  PHONE: "phone",
  QUERY: "query",
  FOLLOW_UP_DATE: "followUpDate",
};

const AddEnquiry = () => {
  const navigate = useNavigate();
  useSetActiveTab(TAB_NAMES.ENQUIRY);
  const { enquiryId } = useParams();
  const { errorNotification, successNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  const {
    inProgress,
    execute: addEnquiry,
    isSuccess,
    error: addErr,
  } = useAddGymEnquiry();
  const { data: memberResponse, isLoading: isGetGymExpensesLoading } =
    useGetGymEnquiryInfo(Number(enquiryId));
  const {
    execute: updateMember,
    isSuccess: isUpdateSuuccess,
    error: updateErr,
    inProgress: isUpdateInProgress,
  } = useUpdateGymEnquiry();

  const memberData = useMemo(() => {
    return memberResponse?.data ?? {};
  }, [memberResponse?.data]);

  const { values, errors, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: false,
      initialValues: {
        [FORM_FIELDS.NAME]: memberData?.[FORM_FIELDS.NAME] ?? "",
        [FORM_FIELDS.PHONE]: memberData?.[FORM_FIELDS.PHONE] ?? null,
        [FORM_FIELDS.EMAIL]: memberData?.[FORM_FIELDS.EMAIL] ?? "",
        [FORM_FIELDS.GENDER]: memberData?.[FORM_FIELDS.GENDER] ?? null,
        [FORM_FIELDS.SOURCE]: memberData?.[FORM_FIELDS.SOURCE] ?? null,
        [FORM_FIELDS.LEAD_STATUS]:
          memberData?.[FORM_FIELDS.LEAD_STATUS] ?? null,
        [FORM_FIELDS.QUERY]: memberData?.[FORM_FIELDS.QUERY] ?? "",
        [FORM_FIELDS.FOLLOW_UP_DATE]:
          memberData?.[FORM_FIELDS.FOLLOW_UP_DATE] ?? null,
      },
      validationSchema: object({
        [FORM_FIELDS.NAME]: string().required("This is a Required field."),
        [FORM_FIELDS.PHONE]: number().required("This is a Required field."),
        [FORM_FIELDS.EMAIL]: string()
          .email()
          .required("This is a Required field."),
      }),
      onSubmit: (data) => {
        if (enquiryId) {
          updateMember({ ...data, enquiryId });
          return;
        }
        addEnquiry(data);
      },
    });

  useEffect(() => {
    if (isSuccess || isUpdateSuuccess) {
      successNotification();
      navigate("/enquiry");
    }
  }, [isSuccess, isUpdateSuuccess, navigate, successNotification]);

  useEffect(() => {
    if (updateErr || addErr) {
      errorNotification();
    }
  }, [addErr, errorNotification, updateErr]);

  const restFormDisabled = useMemo(() => {
    return false;
  }, []);

  return (
    <div className="flex flex-col bg-white w-full rounded-xl">
      <div className="text-xl font-bold flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div
            className="rounded-full h-10 w-10 flex p-3 justify-center items-center bg-[#F4F7FE] cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon />
          </div>
          <span>Add Enquiry</span>
        </div>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          loading={inProgress || isUpdateInProgress}
        >
          Submit
        </Button>
      </div>
      <HorizontalSeparator />
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 ">
            Enquiry Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Here you can add the enquiry made for the Gym.
          </p>
        </div>

        {isGetGymExpensesLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        ) : (
          <form className="md:col-span-2">
            <div className="flex flex-col gap-3">
              <div className="flex gap-3">
                <Input
                  id={FORM_FIELDS.NAME}
                  label="Name"
                  name={FORM_FIELDS.NAME}
                  value={values[FORM_FIELDS.NAME]}
                  error={
                    errors[FORM_FIELDS.NAME] &&
                    touched[FORM_FIELDS.NAME] &&
                    errors[FORM_FIELDS.NAME]
                  }
                  onChange={handleChange}
                  disabled={restFormDisabled}
                  required
                />
                <ElementWithLabel
                  label="Gender"
                  error={
                    errors[FORM_FIELDS.GENDER] &&
                    touched[FORM_FIELDS.GENDER] &&
                    errors[FORM_FIELDS.GENDER]
                  }
                >
                  <Select
                    placeholder="Select Gender"
                    optionFilterProp="children"
                    value={values[FORM_FIELDS.GENDER]}
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.GENDER, value)
                    }
                    onSearch={() => {}}
                    options={genderOptions}
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
              </div>
              <div className="flex gap-3">
                <Input
                  id={FORM_FIELDS.EMAIL}
                  type="email"
                  label="Email"
                  name={FORM_FIELDS.EMAIL}
                  value={values[FORM_FIELDS.EMAIL]}
                  error={
                    errors[FORM_FIELDS.EMAIL] &&
                    touched[FORM_FIELDS.EMAIL] &&
                    errors[FORM_FIELDS.EMAIL]
                  }
                  onChange={handleChange}
                  required
                />

                <Input
                  id={FORM_FIELDS.PHONE}
                  type="number"
                  label="Mobile No."
                  name={FORM_FIELDS.PHONE}
                  value={values[FORM_FIELDS.PHONE]}
                  error={
                    errors[FORM_FIELDS.PHONE] &&
                    touched[FORM_FIELDS.PHONE] &&
                    errors[FORM_FIELDS.PHONE]
                  }
                  onChange={handleChange}
                  disabled={restFormDisabled}
                  required
                />
              </div>
              <div className="flex gap-3">
                <ElementWithLabel
                  label="Source"
                  error={
                    errors[FORM_FIELDS.SOURCE] &&
                    touched[FORM_FIELDS.SOURCE] &&
                    errors[FORM_FIELDS.SOURCE]
                  }
                >
                  <Select
                    placeholder="Select Source"
                    optionFilterProp="children"
                    value={values[FORM_FIELDS.SOURCE]}
                    onChange={(value) => {
                      setFieldValue(FORM_FIELDS.SOURCE, value);
                    }}
                    onSearch={() => {}}
                    options={LEAD_SOURCE_OPTIONS}
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label="Lead Status"
                  error={
                    errors[FORM_FIELDS.LEAD_STATUS] &&
                    touched[FORM_FIELDS.LEAD_STATUS] &&
                    errors[FORM_FIELDS.LEAD_STATUS]
                  }
                >
                  <Select
                    placeholder="Select Status"
                    optionFilterProp="children"
                    value={values[FORM_FIELDS.LEAD_STATUS]}
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.LEAD_STATUS, value)
                    }
                    onSearch={() => {}}
                    options={LEAD_STATUS_OPTIONS}
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
              </div>
              <ElementWithLabel
                label="Query"
                error={
                  errors[FORM_FIELDS.QUERY] &&
                  touched[FORM_FIELDS.QUERY] &&
                  errors[FORM_FIELDS.QUERY]
                }
              >
                <TextArea
                  rows={4}
                  name="query"
                  placeholder="Write Query"
                  onChange={(e) =>
                    setFieldValue(FORM_FIELDS.QUERY, e.target.value)
                  }
                  value={values[FORM_FIELDS.QUERY]}
                />
              </ElementWithLabel>
              <ElementWithLabel
                label="Next Follow Up Date"
                error={
                  errors[FORM_FIELDS.FOLLOW_UP_DATE] &&
                  touched[FORM_FIELDS.FOLLOW_UP_DATE] &&
                  errors[FORM_FIELDS.FOLLOW_UP_DATE]
                }
              >
                <DatePicker
                  showTime
                  onChange={(value, dateString) => {
                    console.log("Selected Time: ", value);
                    console.log("Formatted Selected Time: ", dateString);
                  }}
                  onOk={(value) => {
                    console.log("line 284 ----------> ", value);
                    setFieldValue(FORM_FIELDS.FOLLOW_UP_DATE, value);
                  }}
                  value={
                    values[FORM_FIELDS.FOLLOW_UP_DATE]
                      ? dayjs(values[FORM_FIELDS.FOLLOW_UP_DATE])
                      : null
                  }
                />
              </ElementWithLabel>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddEnquiry;

const expensesOptions = [
  {
    value: 1,
    label: "Cleaning",
  },
  {
    value: 2,
    label: "Electricity Bill",
  },
  {
    value: 3,
    label: "Water Bill",
  },
  {
    value: 4,
    label: "Staff Salary",
  },
  {
    value: 5,
    label: "Equipment Buying",
  },
  {
    value: 6,
    label: "Maintenance",
  },
  {
    value: 7,
    label: "Others",
  },
];

export const getExpensesLabel = (expenseId) => {
  return expensesOptions.find((item) => item.value === expenseId)?.label;
};
