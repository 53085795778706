import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSetActiveTab from "../../../components/hooks/useSetActiveTab";
import TabHeader from "../../../components/layouts/TabHeader";
import { TAB_NAMES } from "../../../components/layouts/menuNavigation";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useNotification } from "../../../components/notification/NotificationContext";
import { getLeadStatus } from "../../../utils/constants";
import { getEnquirySourceLabel } from "../../../utils/helpers";
import { getGender } from "../../members/list/MembersList";
import { useDeleteGymEnquiry, useGetGymEnquiries } from "../apis";
import { getExpensesLabel } from "../create/AddEnquiry";

const EnquiryList = () => {
  useSetActiveTab(TAB_NAMES.ENQUIRY);
  const navigate = useNavigate();
  const { successNotification, errorNotification } = useNotification();
  const [idToOperatem, setIdToOperate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteClick = useCallback((enquiryId) => {
    setIdToOperate(enquiryId);
    setIsDeleteModalOpen(true);
  }, []);

  const columns = useColumns(handleDeleteClick);
  const {
    isLoading,
    data,
    mutate: refreshEnquiry,
    isValidating,
  } = useGetGymEnquiries();

  const {
    isLoading: isDeleteLoading,
    execute: deleteEnquiry,
    error: deleteEnquiryError,
    isSuccess: deleteEnquirySuccess,
  } = useDeleteGymEnquiry();

  useEffect(() => {
    if (deleteEnquirySuccess) {
      refreshEnquiry();
      successNotification();
      setIsDeleteModalOpen(false);
    }
  }, [deleteEnquirySuccess, refreshEnquiry, successNotification]);

  useEffect(() => {
    if (deleteEnquiryError) {
      errorNotification();
    }
  }, [deleteEnquiryError, errorNotification]);

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onSubmit={() => deleteEnquiry({ enquiryId: idToOperatem })}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
      <div className="flex flex-col gap-5">
        <TabHeader
          label="Enquiries"
          buttonLabel="Add Enquiry"
          onButtonClick={() => navigate("/enquiry/add")}
          onReload={refreshEnquiry}
        />
        <Table
          columns={columns}
          dataSource={data?.data?.rows ?? []}
          loading={isLoading || isValidating}
          scroll={{ x: true }}
        />
      </div>
    </>
  );
};

export default EnquiryList;

const useColumns = (handleDeleteClick) => {
  const navigate = useNavigate();

  return [
    {
      title: () => <div className="flex justify-center">Name</div>,
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div className="flex justify-center">{text ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Contact Info</div>,
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <div className="flex flex-col">
          <div className="flex justify-start gap-2">
            <span className="font-semibold">Email:</span> {text ?? "-"}
          </div>
          <div className="flex justify-start gap-2">
            <span className="font-semibold">Phone:</span> {record?.phone ?? "-"}
          </div>
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Query</div>,
      dataIndex: "query",
      key: "query",
      render: (text) => (
        <div className="flex justify-center">{text ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Gender</div>,
      dataIndex: "gender",
      key: "gender",
      className: "min-w-[70px]",
      render: (text) => (
        <div className="flex justify-center">{getGender(text) ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Source</div>,
      dataIndex: "source",
      key: "source",
      className: "min-w-[70px]",
      render: (text) => (
        <div className="flex justify-center">
          <Tag>{getEnquirySourceLabel(text) ?? "-"}</Tag>
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Lead Status</div>,
      dataIndex: "leadStatus",
      key: "leadStatus",
      render: (text) => (
        <div className="flex justify-center">{getLeadStatus(text) ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Follow Up Date</div>,
      dataIndex: "followUpDate",
      key: "followUpDate",
      render: (text) => (
        <div className="flex justify-center">
          {text ? dayjs(text).format("DD MMM YYYY HH:MM") : "-"}
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Created By</div>,
      dataIndex: "creator",
      key: "creator",
      render: (text) => (
        <div className="flex justify-center">{text?.name ?? "-"}</div>
      ),
    },

    {
      title: () => <div className="flex justify-center">Actions</div>,
      key: "id",
      // fixed: "right",
      render: (_, record) => (
        <div className="flex justify-center gap-3">
          <Button
            type="primary"
            onClick={() => navigate(`/enquiry/add/${record.id}`)}
            icon={<EditOutlined />}
          ></Button>
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteClick(record.id)}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
      ),
    },
  ];
};

const getPaymentMode = (modeId) => {
  switch (modeId) {
    case 1:
      return <Tag color="magenta">{getExpensesLabel(1)}</Tag>;
    case 2:
      return <Tag color="magenta">{getExpensesLabel(2)}</Tag>;
    case 3:
      return <Tag color="volcano">{getExpensesLabel(3)}</Tag>;
    case 4:
      return <Tag color="volcano">{getExpensesLabel(4)}</Tag>;
    case 5:
      return <Tag color="volcano">{getExpensesLabel(5)}</Tag>;
    case 6:
      return <Tag color="volcano">{getExpensesLabel(6)}</Tag>;
    default:
      return <Tag color="gold">{getExpensesLabel(7)}</Tag>;
  }
};

export const DateCell = ({ date }) => {
  return (
    <div className="flex justify-center">
      {date ? dayjs(date).format("DD MMM YYYY") : "-"}
    </div>
  );
};
