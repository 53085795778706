import dayjs from "dayjs";
import { LEAD_SOURCE_OPTIONS } from "./constants";

export function addOpacityToRGB(rgbColor, opacity) {
  const rgbValues = rgbColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (rgbValues) {
    const r = parseInt(rgbValues[1], 10);
    const g = parseInt(rgbValues[2], 10);
    const b = parseInt(rgbValues[3], 10);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  } else {
    return rgbColor;
  }
}

export const getTokenFromLocalStorage = () => localStorage.getItem("token");

export const setTokenInLocalStorage = (token) =>
  localStorage.setItem("token", token);

export const logout = () => localStorage.clear();

export const wellFormedImageUrl = (imagePath) =>
  `${process.env.REACT_APP_API_URL}/uploads/${imagePath}`;

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const addQueryParametersToUrl = (params) => {
  let queryString = "?";

  Object.keys(params).forEach((item, index) => {
    queryString += `${item}=${params[item]}${
      index + 1 === Object.keys(params).length ? "" : "&"
    }`;
  });

  return queryString;
};

export const mapperDayWise = (exercises) => {
  const uniqueId = [...new Set(exercises.map((item) => item.dayId))];
  const mappedData = {};
  uniqueId.forEach((dayId, index) => {
    mappedData[dayId] = exercises.filter(
      (exercise) => exercise.dayId === dayId
    );
  });

  return mappedData;
};

export const isDateLessThanToday = (inputDate) => {
  // Parse the input date with Day.js
  const dateToCheck = dayjs(inputDate);
  // Get today's date
  const today = dayjs().startOf("day"); // Reset time to midnight for accurate comparison

  // Check if the input date is before today
  return dateToCheck.isBefore(today);
};

export const getEnquirySourceLabel = (sourceId) => {
  return LEAD_SOURCE_OPTIONS.find((item) => item.value === sourceId)?.label;
};
