import { notification } from "antd";
import { createContext, useCallback, useContext } from "react";

const NotificationContext = createContext({});
const NotificationContextProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const successNotification = useCallback(
    (message) => {
      api.success({
        message: message ?? "Changes made Successfully.",
        duration: 4,
      });
    },
    [api]
  );

  const errorNotification = useCallback(
    (message) => {
      api.error({
        message: message ?? "Something went wrong.",
        duration: 4,
      });
    },
    [api]
  );

  return (
    <>
      {contextHolder}
      <NotificationContext.Provider
        value={{ errorNotification, successNotification }}
      >
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export default NotificationContextProvider;

export const useNotification = () => {
  return useContext(NotificationContext);
};
