import { ClockCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect } from "react";
import {
  useAddGymCheckIn,
  useGetGymCheckInStatus,
  useUpdateGymCheckoutTime,
} from "./apis";

const CheckIn = () => {
  const { isLoading, data, mutate } = useGetGymCheckInStatus();
  const {
    isLoading: isAddLoading,
    execute: checkIn,
    isSuccess,
  } = useAddGymCheckIn();

  const {
    isLoading: isCheckoutLoading,
    execute: checkOut,
    isSuccess: isCheckOutSuccess,
  } = useUpdateGymCheckoutTime();

  const hasData = !!data?.data?.date;

  useEffect(() => {
    if (isSuccess || isCheckOutSuccess) {
      mutate();
    }
  }, [isCheckOutSuccess, isSuccess, mutate]);

  if (isLoading) {
    return null;
  }

  if (hasData && !data?.data?.endTime) {
    return (
      <Button
        type="primary"
        danger
        icon={<ClockCircleOutlined />}
        onClick={checkOut}
      >
        {isCheckoutLoading ? "Loading..." : "Check Out"}
      </Button>
    );
  }
  return (
    <Button
      type="primary"
      icon={<ClockCircleOutlined />}
      onClick={() => checkIn()}
      disabled={data?.data?.endTime}
    >
      {isAddLoading ? "Loading..." : "Check In"}
    </Button>
  );
};

export default CheckIn;
