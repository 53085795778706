import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../../../hooks/useFetchAsync";
import { get, patch, post } from "../../../../http-clients/clients";
import { getActiveGymId } from "../../../../redux/selectors/app";

export const useAddGymCheckIn = () => {
  const gymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return post(`/gym/check-in/${gymId}`, {});
    },
    [gymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymCheckInStatus = () => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(`/gym/check-in/${activeGymId}`, get);
};

export const useUpdateGymCheckoutTime = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return patch(`/gym/check-in/${activeGymId}`, {});
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};
