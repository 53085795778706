import { LoadingOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, DatePicker, Select, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { number, object, string } from "yup";
import useSetActiveTab from "../../../../components/hooks/useSetActiveTab";
import { ElementWithLabel } from "../../../../components/inputs/Input";
import { TAB_NAMES } from "../../../../components/layouts/menuNavigation";
import { useNotification } from "../../../../components/notification/NotificationContext";
import { HorizontalSeparator } from "../../../members/create/AddMember";
import { useGetGymStaff } from "../../../staff/apis";
import {
  useAddGymAttendance,
  useGetGymAttendanceInfo,
  useUpdateGymAttendance,
} from "../apis";

const FORM_FIELDS = {
  STAFF: "staffId",
  DATE: "date",
  START_TIME: "startTime",
  END_TIME: "endTime",
};

const AddAttendance = () => {
  const navigate = useNavigate();
  useSetActiveTab(TAB_NAMES.ATTENDANCE);
  const { attendanceId: memberId } = useParams();
  const { errorNotification, successNotification } = useNotification();
  const { isLoading, data, mutate: refreshStaffList } = useGetGymStaff();
  const staffData = useMemo(() => data?.data?.rows ?? [], [data?.data]);
  const mappedStaffData = useMemo(() =>
    staffData.map((item) => ({ value: item.id, label: item.name }))
  );

  const {
    inProgress,
    execute: addExpense,
    isSuccess,
    error: addErr,
  } = useAddGymAttendance();

  const { data: memberResponse, isLoading: isGetGymExpensesLoading } =
    useGetGymAttendanceInfo(Number(memberId));

  const {
    execute: updateMember,
    isSuccess: isUpdateSuuccess,
    error: updateErr,
    inProgress: isUpdateInProgress,
  } = useUpdateGymAttendance();

  const memberData = useMemo(() => {
    return memberResponse?.data ?? {};
  }, [memberResponse?.data]);

  const { values, errors, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: false,
      initialValues: {
        [FORM_FIELDS.STAFF]: memberData?.[FORM_FIELDS.STAFF]
          ? Number(memberData?.[FORM_FIELDS.STAFF])
          : null,
        [FORM_FIELDS.DATE]: memberData?.[FORM_FIELDS.DATE] ?? null,
        [FORM_FIELDS.START_TIME]: memberData?.[FORM_FIELDS.START_TIME] ?? null,
        [FORM_FIELDS.END_TIME]: memberData?.[FORM_FIELDS.END_TIME] ?? null,
      },
      validationSchema: object({
        [FORM_FIELDS.STAFF]: number().required("This is a Required field."),
        [FORM_FIELDS.DATE]: string().required("This is a Required field."),
        [FORM_FIELDS.START_TIME]: string().required(
          "This is a Required field."
        ),
        [FORM_FIELDS.END_TIME]: string().required("This is a Required field."),
      }),
      onSubmit: (data) => {
        if (memberId) {
          updateMember({ ...data, attendanceId: memberId });
          return;
        }
        addExpense(data);
      },
    });

  useEffect(() => {
    if (isSuccess || isUpdateSuuccess) {
      successNotification();
      navigate("/attendance");
    }
  }, [isSuccess, isUpdateSuuccess, navigate, successNotification]);

  useEffect(() => {
    if (updateErr || addErr) {
      errorNotification();
    }
  }, [addErr, errorNotification, updateErr]);

  const restFormDisabled = useMemo(() => {
    return false;
  }, []);

  const onSearch = (value) => {
    console.log("search:", value);
    const data = mappedStaffData.find((item) =>
      item?.label?.toLowerCase()?.includes(value?.toLowerCase())
    )?.value;

    setFieldValue(FORM_FIELDS.STAFF, data);
  };

  console.log("line 111 ", mappedStaffData);

  const format = "HH:mm";

  return (
    <div className="flex flex-col bg-white w-full rounded-xl">
      <div className="text-xl font-bold flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div
            className="rounded-full h-10 w-10 flex p-3 justify-center items-center bg-[#F4F7FE] cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon />
          </div>
          <span>Add Attendance</span>
        </div>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          loading={inProgress || isUpdateInProgress}
        >
          Submit
        </Button>
      </div>
      <HorizontalSeparator />
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 ">
            Attendance Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Here you can add the Attendance of your staff.
          </p>
        </div>

        {isGetGymExpensesLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        ) : (
          <form className="md:col-span-2">
            <div className="flex flex-col gap-3">
              <ElementWithLabel
                label="Staff"
                error={
                  errors[FORM_FIELDS.STAFF] &&
                  touched[FORM_FIELDS.STAFF] &&
                  errors[FORM_FIELDS.STAFF]
                }
              >
                <Select
                  showSearch
                  placeholder="Select Staff"
                  optionFilterProp="label"
                  value={values[FORM_FIELDS.STAFF]}
                  onChange={(value) => setFieldValue(FORM_FIELDS.STAFF, value)}
                  // onSearch={onSearch}
                  options={mappedStaffData}
                  loading={isLoading}
                />
              </ElementWithLabel>

              <div className="flex gap-3">
                <ElementWithLabel
                  label={"Date"}
                  name={FORM_FIELDS.DATE}
                  error={
                    errors[FORM_FIELDS.DATE] &&
                    touched[FORM_FIELDS.DATE] &&
                    errors[FORM_FIELDS.DATE]
                  }
                  required
                >
                  <DatePicker
                    value={
                      values[FORM_FIELDS.DATE]
                        ? dayjs(values[FORM_FIELDS.DATE])
                        : null
                    }
                    onChange={(value) => setFieldValue(FORM_FIELDS.DATE, value)}
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label={"Start Time"}
                  name={FORM_FIELDS.START_TIME}
                  error={
                    errors[FORM_FIELDS.START_TIME] &&
                    touched[FORM_FIELDS.START_TIME] &&
                    errors[FORM_FIELDS.START_TIME]
                  }
                  required
                >
                  <TimePicker
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.START_TIME, value)
                    }
                    defaultOpenValue={dayjs("00:00", "HH:mm")}
                    value={
                      values[FORM_FIELDS.START_TIME]
                        ? dayjs(values[FORM_FIELDS.START_TIME])
                        : null
                    }
                    format={format}
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label={"End Time"}
                  name={FORM_FIELDS.END_TIME}
                  error={
                    errors[FORM_FIELDS.END_TIME] &&
                    touched[FORM_FIELDS.END_TIME] &&
                    errors[FORM_FIELDS.END_TIME]
                  }
                  required
                >
                  <TimePicker
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.END_TIME, value)
                    }
                    defaultOpenValue={dayjs("00:00", "HH:mm")}
                    value={
                      values[FORM_FIELDS.END_TIME]
                        ? dayjs(values[FORM_FIELDS.END_TIME])
                        : null
                    }
                    format={format}
                  />
                </ElementWithLabel>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddAttendance;
