import { LoadingOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";
import useSetActiveTab from "../../../../components/hooks/useSetActiveTab";
import Input, { ElementWithLabel } from "../../../../components/inputs/Input";
import { TAB_NAMES } from "../../../../components/layouts/menuNavigation";
import { useNotification } from "../../../../components/notification/NotificationContext";
import { HorizontalSeparator } from "../../../members/create/AddMember";
import { useAddGymShift, useGetGymShiftInfo, useUpdateGymShift } from "../apis";

const FORM_FIELDS = {
  NAME: "name",
  START_TIME: "startTime",
  END_TIME: "endTime",
};

const AddShift = () => {
  const navigate = useNavigate();
  useSetActiveTab(TAB_NAMES.EXPENSES);
  const { shiftId: memberId } = useParams();
  const { errorNotification, successNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  const {
    inProgress,
    execute: addExpense,
    isSuccess,
    error: addErr,
  } = useAddGymShift();
  const { data: memberResponse, isLoading: isGetGymExpensesLoading } =
    useGetGymShiftInfo(Number(memberId));
  const {
    execute: updateMember,
    isSuccess: isUpdateSuuccess,
    error: updateErr,
    inProgress: isUpdateInProgress,
  } = useUpdateGymShift();

  const memberData = useMemo(() => {
    return memberResponse?.data ?? {};
  }, [memberResponse?.data]);

  const { values, errors, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: false,
      initialValues: {
        [FORM_FIELDS.NAME]: memberData?.[FORM_FIELDS.NAME] ?? "",
        [FORM_FIELDS.START_TIME]: memberData?.[FORM_FIELDS.START_TIME] ?? null,
        [FORM_FIELDS.END_TIME]: memberData?.[FORM_FIELDS.END_TIME] ?? null,
      },
      validationSchema: object({
        [FORM_FIELDS.NAME]: string().required("This is a Required field."),
        [FORM_FIELDS.START_TIME]: string().required(
          "This is a Required field."
        ),
        [FORM_FIELDS.END_TIME]: string().required("This is a Required field."),
      }),
      onSubmit: (data) => {
        if (memberId) {
          updateMember({ ...data, expenseId: memberId });
          return;
        }
        addExpense(data);
      },
    });

  useEffect(() => {
    if (isSuccess || isUpdateSuuccess) {
      successNotification();
      navigate("/gym/shifts");
    }
  }, [isSuccess, isUpdateSuuccess, navigate, successNotification]);

  useEffect(() => {
    if (updateErr || addErr) {
      errorNotification();
    }
  }, [addErr, errorNotification, updateErr]);

  const restFormDisabled = useMemo(() => {
    return false;
  }, []);

  const format = "HH:mm";

  return (
    <div className="flex flex-col bg-white w-full rounded-xl">
      <div className="text-xl font-bold flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div
            className="rounded-full h-10 w-10 flex p-3 justify-center items-center bg-[#F4F7FE] cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon />
          </div>
          <span>Add Shift</span>
        </div>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          loading={inProgress || isUpdateInProgress}
        >
          Submit
        </Button>
      </div>
      <HorizontalSeparator />
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 ">
            Shift Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Here you can add the shifts for the Gym.
          </p>
        </div>

        {isGetGymExpensesLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        ) : (
          <form className="md:col-span-2">
            <div className="flex flex-col gap-3">
              <Input
                id={FORM_FIELDS.NAME}
                label="Shift Name"
                name={FORM_FIELDS.NAME}
                value={values[FORM_FIELDS.NAME]}
                error={
                  errors[FORM_FIELDS.NAME] &&
                  touched[FORM_FIELDS.NAME] &&
                  errors[FORM_FIELDS.NAME]
                }
                onChange={handleChange}
                disabled={restFormDisabled}
                required
              />

              <div className="flex gap-3">
                <ElementWithLabel
                  label={"Shift Start Time"}
                  name={FORM_FIELDS.START_TIME}
                  error={
                    errors[FORM_FIELDS.START_TIME] &&
                    touched[FORM_FIELDS.START_TIME] &&
                    errors[FORM_FIELDS.START_TIME]
                  }
                  required
                >
                  <TimePicker
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.START_TIME, value)
                    }
                    defaultOpenValue={dayjs("00:00", "HH:mm")}
                    value={
                      values[FORM_FIELDS.START_TIME]
                        ? dayjs(values[FORM_FIELDS.START_TIME])
                        : null
                    }
                    format={format}
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label={"Shift End Time"}
                  name={FORM_FIELDS.END_TIME}
                  error={
                    errors[FORM_FIELDS.END_TIME] &&
                    touched[FORM_FIELDS.END_TIME] &&
                    errors[FORM_FIELDS.END_TIME]
                  }
                  required
                >
                  <TimePicker
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.END_TIME, value)
                    }
                    defaultOpenValue={dayjs("00:00", "HH:mm")}
                    value={
                      values[FORM_FIELDS.END_TIME]
                        ? dayjs(values[FORM_FIELDS.END_TIME])
                        : null
                    }
                    format={format}
                  />
                </ElementWithLabel>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddShift;
