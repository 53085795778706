import { Tag } from "antd";

export const PRIMARY_COLOR = "rgba(55, 97, 238, 1)";
export const SECONDARY_COLOR = "rgba(238, 131, 54, 1)";
export const TERTIARY_COLOR = "rgba(45, 182, 245, 1)";
export const QUATERNARY_COLOR = "rgba(187, 80, 295, 1)";
export const QUINARY_COLOR = "rgba(0, 182, 155, 1)";
export const SENARY_COLOR = "rgba(175, 193, 255, 1)";
export const SEPTENARY_COLOR = "rgba(255, 207, 159, 1)";
export const OCTONARY_COLOR = "rgba(130, 240, 255, 1)";

export const CHART_COLORS = {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERTIARY_COLOR,
  QUATERNARY_COLOR,
  QUINARY_COLOR,
  SENARY_COLOR,
  SEPTENARY_COLOR,
  OCTONARY_COLOR,
};

export const LEAD_SOURCE_OPTIONS = [
  {
    label: "Email",
    value: 1,
  },
  {
    label: "Phone",
    value: 2,
  },
  {
    label: "Instagram",
    value: 3,
  },
  {
    label: "Facebook",
    value: 4,
  },
  {
    label: "Twitter",
    value: 5,
  },
  {
    label: "In Person",
    value: 6,
  },
  {
    label: "Others",
    value: 7,
  },
];

export const LEAD_STATUS_OPTIONS = [
  {
    label: "Pending",
    value: 1,
  },
  {
    label: "In Progress",
    value: 2,
  },
  {
    label: "Success",
    value: 3,
  },
  {
    label: "Failed",
    value: 4,
  },
];

export const getLeadStatus = (leadStatus) => {
  switch (leadStatus) {
    case 2:
      return <Tag color="magenta">In Progress</Tag>;
    case 3:
      return <Tag color="volcano">Success</Tag>;
    case 4:
      return <Tag color="gold">Failed</Tag>;
    default:
      return <Tag>Pending</Tag>;
  }
};
