import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../hooks/useFetchAsync";
import { deleteReq, get, patch, post } from "../../http-clients/clients";
import { getActiveGymId } from "../../redux/selectors/app";

export const useAddGymStaff = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return post("/gym/staff/create", {
        ...data,
        gymId: activeGymId,
        type: 1,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export const useGetGymStaff = () => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(`/gym/staff/${activeGymId}`, get);
};

export const useDeleteGymStaff = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return deleteReq(`/gym/staff/${activeGymId}`, { ...data });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export const useGetStaffMember = (staffId) => {
  const activeGymId = useSelector(getActiveGymId);
  return useSWR(
    staffId ? `/gym/staff/${activeGymId}/member/${staffId}` : null,
    get
  );
};

export const useUpdateGymStaff = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return patch(`/gym/staff/member`, {
        ...data,
        gymId: activeGymId,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export const useUpdateMemberStatus = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (status) => {
      return patch(`/gym/staff/member`, {
        status,
        gymId: activeGymId,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};
