import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../hooks/useFetchAsync";
import { deleteReq, get, patch, post } from "../../http-clients/clients";
import { getActiveGymId } from "../../redux/selectors/app";

export const useAddGymEnquiry = () => {
  const gymId = useSelector(getActiveGymId);
  const addEnquiry = useCallback(
    (data) => {
      return post(`/gym/enquiry/${gymId}`, { ...data });
    },
    [gymId]
  );

  return useFetchAsync(addEnquiry);
};

export const useGetGymEnquiries = () => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(`/gym/enquiry/${activeGymId}`, get);
};

export const useDeleteGymEnquiry = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return deleteReq(`/gym/enquiry/${activeGymId}`, { ...data });
    },
    [activeGymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymEnquiryInfo = (enquiryId) => {
  const activeGymId = useSelector(getActiveGymId);
  return useSWR(
    enquiryId ? `/gym/enquiry/${activeGymId}/${enquiryId}` : null,
    get
  );
};

export const useUpdateGymEnquiry = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return patch(`/gym/enquiry/${activeGymId}/update`, data);
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export const useUpdateMemberStatus = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (status) => {
      return patch(`/gym/member/update`, {
        status,
        gymId: activeGymId,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};
