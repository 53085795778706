import { LoadingOutlined } from "@ant-design/icons";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { Button, DatePicker, InputNumber, Select, Spin, Upload } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { number, object, string } from "yup";
import useSetActiveTab from "../../../components/hooks/useSetActiveTab";
import Input, { ElementWithLabel } from "../../../components/inputs/Input";
import { TAB_NAMES } from "../../../components/layouts/menuNavigation";
import { useNotification } from "../../../components/notification/NotificationContext";
import { HorizontalSeparator } from "../../members/create/AddMember";
import {
  useAddGymExpense,
  useGetGymExpenseInfo,
  useUpdateGymMember,
} from "../apis";

const FORM_FIELDS = {
  IMAGE: "image",
  NAME: "name",
  AMOUNT: "amount",
  PHONE: "mobile",
  CATEGORY: "category",
  DATE: "date",
};

const AddExpenses = () => {
  const navigate = useNavigate();
  useSetActiveTab(TAB_NAMES.EXPENSES);
  const { expenseId: memberId } = useParams();
  const { errorNotification, successNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  const {
    inProgress,
    execute: addExpense,
    isSuccess,
    error: addErr,
  } = useAddGymExpense();
  const { data: memberResponse, isLoading: isGetGymExpensesLoading } =
    useGetGymExpenseInfo(Number(memberId));
  const {
    execute: updateMember,
    isSuccess: isUpdateSuuccess,
    error: updateErr,
    inProgress: isUpdateInProgress,
  } = useUpdateGymMember();

  const memberData = useMemo(() => {
    return memberResponse?.data ?? {};
  }, [memberResponse?.data]);

  const { values, errors, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: false,
      initialValues: {
        [FORM_FIELDS.NAME]: memberData?.[FORM_FIELDS.NAME] ?? "",
        [FORM_FIELDS.IMAGE]: memberData?.[FORM_FIELDS.IMAGE] ?? "",
        [FORM_FIELDS.AMOUNT]: memberData?.[FORM_FIELDS.AMOUNT] ?? "",
        [FORM_FIELDS.CATEGORY]: memberData?.[FORM_FIELDS.CATEGORY] ?? "",
        [FORM_FIELDS.DATE]: memberData?.[FORM_FIELDS.DATE] ?? null,
      },
      validationSchema: object({
        [FORM_FIELDS.NAME]: string().required("This is a Required field."),
        [FORM_FIELDS.AMOUNT]: number().required("This is a Required field."),
        [FORM_FIELDS.CATEGORY]: number().required("This is a Required field."),
        [FORM_FIELDS.DATE]: string().required("This is a Required field."),
      }),
      onSubmit: (data) => {
        if (memberId) {
          updateMember({ ...data, expenseId: memberId });
          return;
        }
        addExpense(data);
      },
    });

  const handleImageChange = (info) => {
    setFieldValue(FORM_FIELDS.IMAGE, info.file.originFileObj);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file?.type === "image/jpeg" || file?.type === "image/png";
    if (!isJpgOrPng) {
      errorNotification("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorNotification("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  useEffect(() => {
    if (isSuccess || isUpdateSuuccess) {
      successNotification();
      navigate("/expenses");
    }
  }, [isSuccess, isUpdateSuuccess, navigate, successNotification]);

  useEffect(() => {
    if (updateErr || addErr) {
      errorNotification();
    }
  }, [addErr, errorNotification, updateErr]);

  const restFormDisabled = useMemo(() => {
    return false;
  }, []);

  return (
    <div className="flex flex-col bg-white w-full rounded-xl">
      <div className="text-xl font-bold flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div
            className="rounded-full h-10 w-10 flex p-3 justify-center items-center bg-[#F4F7FE] cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon />
          </div>
          <span>Add Expense</span>
        </div>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          loading={inProgress || isUpdateInProgress}
        >
          Submit
        </Button>
      </div>
      <HorizontalSeparator />
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 ">
            Expense Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Here you can the expense you made for the Gym.
          </p>
        </div>

        {isGetGymExpensesLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        ) : (
          <form className="md:col-span-2">
            <div className="flex flex-col gap-3">
              <div className="col-span-full flex items-center gap-x-8">
                <Upload
                  name={[FORM_FIELDS.IMAGE]}
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  //action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  beforeUpload={beforeUpload}
                  onChange={handleImageChange}
                  customRequest={() => {}}
                  disabled={restFormDisabled}
                >
                  {values[FORM_FIELDS.IMAGE] ? (
                    <img
                      src={
                        typeof values[FORM_FIELDS.IMAGE] === "string"
                          ? `${process.env.REACT_APP_API_URL}/uploads/${
                              values[FORM_FIELDS.IMAGE]
                            }`
                          : URL.createObjectURL(values[FORM_FIELDS.IMAGE])
                      }
                      alt="avatar"
                      style={{ width: "100%", height: "100%" }}
                      className="object-cover"
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <div>
                  <button
                    type="button"
                    className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold  shadow-sm hover:bg-white/20"
                  >
                    Upload Document
                  </button>
                  <p className="mt-2 text-xs leading-5 text-gray-400">
                    JPG, GIF or PNG. 1MB max.
                  </p>
                </div>
              </div>

              <Input
                id={FORM_FIELDS.NAME}
                label="Name (Paid To)"
                name={FORM_FIELDS.NAME}
                value={values[FORM_FIELDS.NAME]}
                error={
                  errors[FORM_FIELDS.NAME] &&
                  touched[FORM_FIELDS.NAME] &&
                  errors[FORM_FIELDS.NAME]
                }
                onChange={handleChange}
                disabled={restFormDisabled}
              />

              <div className="flex gap-3">
                <ElementWithLabel
                  label={"Amount"}
                  name={FORM_FIELDS.AMOUNT}
                  error={
                    errors[FORM_FIELDS.AMOUNT] &&
                    touched[FORM_FIELDS.AMOUNT] &&
                    errors[FORM_FIELDS.AMOUNT]
                  }
                >
                  <InputNumber
                    defaultValue={0}
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.AMOUNT, value)
                    }
                    changeOnWheel
                    value={values[FORM_FIELDS.AMOUNT]}
                    disabled={restFormDisabled}
                    className="w-full"
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label="Category"
                  name={FORM_FIELDS.CATEGORY}
                  error={
                    errors[FORM_FIELDS.CATEGORY] &&
                    touched[FORM_FIELDS.CATEGORY] &&
                    errors[FORM_FIELDS.CATEGORY]
                  }
                >
                  <Select
                    // showSearch
                    name={FORM_FIELDS.CATEGORY}
                    placeholder="Select Category"
                    optionFilterProp="children"
                    value={values[FORM_FIELDS.CATEGORY]}
                    onChange={(value) =>
                      setFieldValue(FORM_FIELDS.CATEGORY, value)
                    }
                    // onSearch={() => {}}
                    options={expensesOptions}
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
                <ElementWithLabel
                  label={"Date of Payment"}
                  name={FORM_FIELDS.DATE}
                  error={
                    errors[FORM_FIELDS.DATE] &&
                    touched[FORM_FIELDS.DATE] &&
                    errors[FORM_FIELDS.DATE]
                  }
                >
                  <DatePicker
                    onChange={(value) => setFieldValue(FORM_FIELDS.DATE, value)}
                    name={FORM_FIELDS.DATE}
                    value={
                      values[FORM_FIELDS.DATE]
                        ? dayjs(values[FORM_FIELDS.DATE])
                        : null
                    }
                    disabled={restFormDisabled}
                  />
                </ElementWithLabel>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddExpenses;

const expensesOptions = [
  {
    value: 1,
    label: "Cleaning",
  },
  {
    value: 2,
    label: "Electricity Bill",
  },
  {
    value: 3,
    label: "Water Bill",
  },
  {
    value: 4,
    label: "Staff Salary",
  },
  {
    value: 5,
    label: "Equipment Buying",
  },
  {
    value: 6,
    label: "Maintenance",
  },
  {
    value: 7,
    label: "Others",
  },
];

export const getExpensesLabel = (expenseId) => {
  return expensesOptions.find((item) => item.value === expenseId)?.label;
};
