import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../../hooks/useFetchAsync";
import { deleteReq, get, patch, post } from "../../../http-clients/clients";
import { getActiveGymId } from "../../../redux/selectors/app";
import { addQueryParametersToUrl } from "../../../utils/helpers";

export const useAddGymAttendance = () => {
  const gymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return post(`/gym/attendance/${gymId}`, { ...data, gymId });
    },
    [gymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymAttendanceList = ({ searchText, date }) => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(
    `/gym/attendance/${activeGymId}${addQueryParametersToUrl({
      search: searchText,
      date,
    })}`,
    get
  );
};

export const useDeleteGymAttendance = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return deleteReq(`/gym/attendance/${activeGymId}`, { ...data });
    },
    [activeGymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymAttendanceInfo = (memberId) => {
  const activeGymId = useSelector(getActiveGymId);
  return useSWR(
    memberId ? `/gym/attendance/${activeGymId}/${memberId}` : null,
    get
  );
};

export const useUpdateGymAttendance = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return patch(`/gym/attendance/${activeGymId}/update`, data);
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};
