import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Image, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSetActiveTab from "../../../components/hooks/useSetActiveTab";
import TabHeader from "../../../components/layouts/TabHeader";
import { TAB_NAMES } from "../../../components/layouts/menuNavigation";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useNotification } from "../../../components/notification/NotificationContext";
import { formatNumber, wellFormedImageUrl } from "../../../utils/helpers";
import { useDeleteGymExpense, useGetGymExpenses } from "../apis";
import { getExpensesLabel } from "../create/AddExpenses";

const ExpensesList = () => {
  useSetActiveTab(TAB_NAMES.EXPENSES);
  const navigate = useNavigate();
  const { successNotification, errorNotification } = useNotification();
  const [idToOperatem, setIdToOperate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteClick = useCallback((staffId) => {
    setIdToOperate(staffId);
    setIsDeleteModalOpen(true);
  }, []);

  const columns = useColumns(handleDeleteClick);
  const {
    isLoading,
    data,
    mutate: refreshExpenses,
    isValidating,
  } = useGetGymExpenses();

  const {
    isLoading: isDeleteLoading,
    execute: deleteGymStaff,
    error: deleteGymStaffError,
    isSuccess: deleteGymStaffSuccess,
  } = useDeleteGymExpense();

  useEffect(() => {
    if (deleteGymStaffSuccess) {
      refreshExpenses();
      successNotification();
      setIsDeleteModalOpen(false);
    }
  }, [deleteGymStaffSuccess, refreshExpenses, successNotification]);

  useEffect(() => {
    if (deleteGymStaffError) {
      errorNotification();
    }
  }, [deleteGymStaffError, errorNotification]);

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onSubmit={() => deleteGymStaff({ expenseId: idToOperatem })}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
      <div className="flex flex-col gap-5">
        <TabHeader
          label="Expenses"
          buttonLabel="Add Expense"
          onButtonClick={() => navigate("/expenses/add")}
          onReload={refreshExpenses}
        />
        <Table
          columns={columns}
          dataSource={data?.data?.rows ?? []}
          loading={isLoading || isValidating}
          scroll={{ x: true }}
        />
      </div>
    </>
  );
};

export default ExpensesList;

const useColumns = (handleDeleteClick) => {
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  return [
    {
      title: () => <div className="flex justify-center">Name</div>,
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div className="flex justify-center">{text ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Amount (Rs.)</div>,
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <div className="flex justify-center">
          {text ? formatNumber(text) : "-"}
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Category</div>,
      dataIndex: "category",
      key: "category",
      render: (text) => (
        <div className="flex justify-center">{getPaymentMode(text) ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Date</div>,
      dataIndex: "date",
      key: "date",
      render: (text) => (
        <div className="flex justify-center">
          {dayjs(text).format("DD MMM YYYY") ?? "-"}
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Created By</div>,
      dataIndex: "creator",
      key: "creator",
      render: (text) => (
        <div className="flex justify-center">{text?.name ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Image</div>,
      dataIndex: "image",
      key: "image",
      render: (text) => {
        return (
          <div className="flex justify-center">
            {text ? (
              <Image
                src={text ? wellFormedImageUrl(text) : "-"}
                alt="document"
                className="h-12 w-12 rounded-lg object-cover cursor-pointer"
                // preview={{
                //   visible: previewOpen,
                //   onVisibleChange: (visible) => setPreviewOpen(visible),
                //   afterOpenChange: (visible) =>
                //     !visible && setPreviewImage(text),
                // }}
                style={{ height: "58px", width: "58px", objectFit: "cover" }}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: () => <div className="flex justify-center">Actions</div>,
      key: "id",
      // fixed: "right",
      render: (_, record) => (
        <div className="flex justify-center gap-3">
          <Button
            type="primary"
            onClick={() => navigate(`/expenses/add/${record.id}`)}
            icon={<EditOutlined />}
          ></Button>
          <Button
            type="primary"
            danger
            onClick={() => handleDeleteClick(record.id)}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
      ),
    },
  ];
};

const getPaymentMode = (modeId) => {
  switch (modeId) {
    case 1:
      return <Tag color="magenta">{getExpensesLabel(1)}</Tag>;
    case 2:
      return <Tag color="magenta">{getExpensesLabel(2)}</Tag>;
    case 3:
      return <Tag color="volcano">{getExpensesLabel(3)}</Tag>;
    case 4:
      return <Tag color="volcano">{getExpensesLabel(4)}</Tag>;
    case 5:
      return <Tag color="volcano">{getExpensesLabel(5)}</Tag>;
    case 6:
      return <Tag color="volcano">{getExpensesLabel(6)}</Tag>;
    default:
      return <Tag color="gold">{getExpensesLabel(7)}</Tag>;
  }
};

export const DateCell = ({ date }) => {
  return (
    <div className="flex justify-center">
      {date ? dayjs(date).format("DD MMM YYYY") : "-"}
    </div>
  );
};
