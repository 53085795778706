import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Badge, Button, Table, Tag } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserDummyImage from "../../../assets/user-dummy.png";
import FilterContextProvider from "../../../components/filters/FilterContext";
import useSetActiveTab from "../../../components/hooks/useSetActiveTab";
import TabHeader from "../../../components/layouts/TabHeader";
import { TAB_NAMES } from "../../../components/layouts/menuNavigation";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useNotification } from "../../../components/notification/NotificationContext";
import {
  isDateLessThanToday,
  wellFormedImageUrl,
} from "../../../utils/helpers";
import { DateCell } from "../../expenses/list/ExpensesList";
import {
  genderOptions,
  statusOptions,
} from "../../workouts/exercise-filters/constants";
import {
  useDeleteGymMember,
  useGetGymMembers,
  useUpdateGymMember,
} from "../apis";

const MembersList = () => {
  return (
    <FilterContextProvider filterOptions={MEMBERS_FILTERS}>
      <MembersListContent />
    </FilterContextProvider>
  );
};

export default MembersList;

const MembersListContent = () => {
  useSetActiveTab(TAB_NAMES.MEMBERS);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { successNotification, errorNotification } = useNotification();
  const [idToOperatem, setIdToOperate] = useState(null);
  const [filters, setFilters] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteClick = useCallback((staffId) => {
    setIdToOperate(staffId);
    setIsDeleteModalOpen(true);
  }, []);

  const { execute: updateMemberStatus, isSuccess: updateSuccess } =
    useUpdateGymMember();

  const handleStatus = ({ staffId, email, value }) => {
    updateMemberStatus({ staffId, email, status: value });
  };
  const columns = useColumns(handleDeleteClick, handleStatus);
  const {
    isLoading,
    data,
    mutate: refreshStaffList,
    mutate,
  } = useGetGymMembers({ searchText, filters });
  const {
    isLoading: isDeleteLoading,
    execute: deleteGymStaff,
    error: deleteGymStaffError,
    isSuccess: deleteGymStaffSuccess,
  } = useDeleteGymMember();

  const modules = useMemo(() => {
    return data?.data?.rows ?? [];
  }, [data?.data]);

  useEffect(() => {
    if (deleteGymStaffSuccess) {
      refreshStaffList();
      successNotification();
      setIsDeleteModalOpen(false);
    }
  }, [deleteGymStaffSuccess, refreshStaffList, successNotification]);

  useEffect(() => {
    if (deleteGymStaffError) {
      errorNotification();
    }
  }, [deleteGymStaffError, errorNotification]);

  useEffect(() => {
    if (updateSuccess) {
      successNotification();
      refreshStaffList();
    }
  }, [refreshStaffList, successNotification, updateSuccess]);

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        isLoading={isDeleteLoading}
        onSubmit={() => deleteGymStaff({ staffId: idToOperatem })}
        onCancel={() => setIsDeleteModalOpen(false)}
      />
      <div className="flex flex-col gap-5">
        <TabHeader
          label={`Members (${data?.data?.count ?? 0})`}
          buttonLabel="Add Member"
          onButtonClick={() => navigate("/members/add")}
          onReload={refreshStaffList}
          onSearch={(text) => setSearchText(text)}
          filters={{ onSubmit: (filters) => setFilters(filters) }}
        />
        <Table
          columns={columns}
          dataSource={modules}
          loading={isLoading}
          scroll={{ x: true }}
        />
      </div>
    </>
  );
};

const useColumns = (handleDeleteClick, handleStatusChange) => {
  const navigate = useNavigate();
  return [
    {
      title: () => <div className="flex justify-left">Member Info</div>,
      dataIndex: "name",
      key: "name",
      className: "min-w-[350px]",
      render: (text, record) => <UserInfoCell record={record} />,
      filterSearch: true,
      onFilter: (value, record) => console.log("line 107 ", value),
    },
    {
      title: () => <div className="flex justify-center">Mobile</div>,
      dataIndex: "mobile",
      key: "mobile",
      className: "min-w-[100px]",
      render: (text, record) => (
        <div className="flex flex-col justify-center">
          <div>{text ?? "-"}</div>
          <div>{record?.emergencyPhone ?? "-"}</div>
        </div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Gender</div>,
      dataIndex: "gender",
      key: "gender",
      className: "min-w-[70px]",
      render: (text) => (
        <div className="flex justify-center">{getGender(text) ?? "-"}</div>
      ),
    },
    {
      title: () => <div className="flex justify-center">Plan</div>,
      dataIndex: "subscriptions",
      key: "subscriptions",
      className: "min-w-[250px]",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        const aSub = getDescSubscription(a?.subscriptions ?? [])[0];
        const bSub = getDescSubscription(b?.subscriptions ?? [])[0];
        return dayjs(aSub?.endDate).unix() - dayjs(bSub?.endDate).unix();
      },
      render: (data) => <PlanCell data={data} />,
    },
    {
      title: () => <div className="flex justify-center">Date Of Birth</div>,
      dataIndex: "dob",
      key: "dob",
      className: "min-w-[100px]",
      render: (_, record) => <DateCell date={record?.dob} />,
    },
    {
      title: () => <div className="flex justify-center">Date Of Joining</div>,
      dataIndex: "doj",
      key: "doj",
      className: "min-w-[100px]",
      render: (_, record) => <DateCell date={record?.doj} />,
    },
    // {
    //   title: () => <div className="flex justify-center">Active</div>,
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) => (
    //     <div className="flex justify-center gap-3">
    //       <Tag title={text} />
    //     </div>
    //   ),
    // },
    {
      title: () => <div className="flex justify-center">Actions</div>,
      key: "id",
      // fixed: "right",
      render: (_, record) => (
        <div className="flex justify-center gap-3">
          <Button
            onClick={() => navigate(`/members/routines/${record.userId}`)}
          >
            Routines
          </Button>
          <Button onClick={() => navigate(`/subscriptions/${record.userId}`)}>
            Subscription
          </Button>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => navigate(`/members/add/${record.userId}`)}
          ></Button>

          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteClick(record.userId)}
          ></Button>
        </div>
      ),
    },
  ];
};

const PlanCell = ({ data = [] }) => {
  const latestSubscription = useMemo(() => {
    return getDescSubscription(data);
  }, [data])[0];

  const isPlanExpired = useMemo(
    () => isDateLessThanToday(latestSubscription?.endDate),
    [latestSubscription?.endDate]
  );

  console.log("line 240 ", isPlanExpired);

  return (
    <div className={classNames("flex flex-col justify-center")}>
      <div className="flex gap-3">
        <span>Name: </span>
        <span
          className={classNames("font-semibold", {
            "!text-red-600": isPlanExpired,
          })}
        >
          {latestSubscription?.planName ?? "-"}
        </span>
      </div>
      <div className="flex gap-3 mb-2">
        <span>Expiry: </span>
        <span
          className={classNames("font-semibold", {
            "!text-red-600": isPlanExpired,
          })}
        >
          <DateCell date={latestSubscription?.endDate} />
        </span>
      </div>
      {isPlanExpired && <Badge count={"Plan Expired"} />}
    </div>
  );
};

const getDescSubscription = (data) => {
  return data.sort((item1, item2) => {
    return dayjs(item2.endDate).unix() - dayjs(item1.endDate).unix();
  });
};

export const getGender = (genderId) => {
  switch (genderId) {
    case 1:
      return <Tag color="magenta">Male</Tag>;
    case 2:
      return <Tag color="volcano">Female</Tag>;
    case 3:
      return <Tag color="gold">Other</Tag>;
    default:
      return "-";
  }
};

export const UserInfoCell = ({ record }) => {
  return (
    <div className="flex gap-3">
      <img
        src={record?.image ? wellFormedImageUrl(record?.image) : UserDummyImage}
        alt="user"
        className="h-[50px] w-[50px] rounded-full shrink-0 object-cover"
      />
      <div className="flex flex-col">
        <div className="flex font-semibold text-slate-950">{record.name}</div>
        <div className="flex">{record.email ?? "-"}</div>
      </div>
    </div>
  );
};

export const MEMBERS_FILTERS = [
  {
    label: "Plan Status",
    value: "status",
    options: statusOptions,
  },
  {
    label: "Genders",
    value: "gender",
    options: genderOptions,
  },
];
