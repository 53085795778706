import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../../hooks/useFetchAsync";
import { deleteReq, get, patch, post } from "../../../http-clients/clients";
import { getActiveGymId } from "../../../redux/selectors/app";

export const useAddGymShift = () => {
  const gymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return post(`/gym/shift/${gymId}`, { ...data, gymId });
    },
    [gymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymShifts = () => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(`/gym/shift/${activeGymId}`, get);
};

export const useDeleteGymShift = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return deleteReq(`/gym/shift/${activeGymId}`, { ...data });
    },
    [activeGymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymShiftInfo = (memberId) => {
  const activeGymId = useSelector(getActiveGymId);
  return useSWR(memberId ? `/gym/shift/${activeGymId}/${memberId}` : null, get);
};

export const useUpdateGymShift = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return patch(`/gym/shift/${activeGymId}/update`, data);
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};
