import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../hooks/useFetchAsync";
import { deleteReq, get, patch, post } from "../../http-clients/clients";
import { getActiveGymId } from "../../redux/selectors/app";

export const useAddGymExpense = () => {
  const gymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return post("/gym/expenses", { ...data, gymId });
    },
    [gymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymExpenses = () => {
  const activeGymId = useSelector(getActiveGymId);

  return useSWR(`/gym/expenses/${activeGymId}`, get);
};

export const useDeleteGymExpense = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addMember = useCallback(
    (data) => {
      return deleteReq(`/gym/expenses/${activeGymId}`, { ...data });
    },
    [activeGymId]
  );

  return useFetchAsync(addMember);
};

export const useGetGymExpenseInfo = (memberId) => {
  const activeGymId = useSelector(getActiveGymId);
  return useSWR(
    memberId ? `/gym/expenses/${activeGymId}/${memberId}` : null,
    get
  );
};

export const useUpdateGymMember = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (data) => {
      return patch(`/gym/expenses/${activeGymId}/update`, data);
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};

export const useUpdateMemberStatus = () => {
  const activeGymId = useSelector(getActiveGymId);
  const addStaff = useCallback(
    (status) => {
      return patch(`/gym/member/update`, {
        status,
        gymId: activeGymId,
      });
    },
    [activeGymId]
  );

  return useFetchAsync(addStaff);
};
